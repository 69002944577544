const state = {
    timelinesChannel: {
        isConnected: null,
    },
    timerChannel: {
        isConnected: null,
    },
};

const getters = {
    timelinesChannel: s => s.timelinesChannel,
    timerChannel: s => s.timerChannel,
};

const mutations = {
    setTimelinesChannel(s, status) {
        s.timelinesChannel.isConnected = status;
    },
    setTimerChannel(s, status) {
        s.timerChannel.isConnected = status;
    },
};

const actions = {
    updateTimelinesChannelStatus({ commit }, status) {
        commit('setTimelinesChannel', status);
    },
    updateTimerChannelStatus({ commit }, status) {
        commit('setTimerChannel', status);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
