<template>
    <div class="filter-panel">
        <div class="filter-header">
            <h2>{{ $t('filters.title') }}</h2>
        </div>
        <div class="clear-filters">
            <button
                class="at-btn crud__control-items__item at-btn--primary at-btn--default at-btn--large"
                @click="clearFilters"
            >
                {{ $t('filters.clearAll') }}
            </button>
        </div>
        <div class="filters">
            <div class="filter-group">
                <label for="dateFilter">{{ $t('filters.date') }}:</label>
                <Calendar
                    id="custom-calendar"
                    :show-range="true"
                    :initialTab="'month'"
                    :sessionStorageKey="'ust.invoice.storage'"
                    @change="handleDateFilterChange"
                />
            </div>

            <div class="filter-group">
                <label>{{ $t('filters.amountRange') }}:</label>
                <div class="amount-range">
                    <input
                        id="minAmountFilter"
                        v-model="filters.amount_min_cents"
                        class="invoice-input"
                        type="number"
                        placeholder="Min"
                        @input="handleFilterChange"
                    />
                    <span class="__symbol">-</span>
                    <input
                        id="maxAmountFilter"
                        v-model="filters.amount_max_cents"
                        class="invoice-input"
                        type="number"
                        placeholder="Max"
                        @input="handleFilterChange"
                    />
                </div>
            </div>

            <div class="filter-group">
                <label for="currencyFilter">{{ $t('filters.currency') }}:</label>
                <select
                    id="currencyFilter"
                    v-model="filters.currency"
                    class="invoice-input"
                    @change="handleFilterChange"
                >
                    <option v-for="currency in currencies" :key="currency" :value="currency">
                        {{ currency }}
                    </option>
                </select>
            </div>

            <div class="filter-group">
                <label for="currencyFilter">{{ $t('filters.paymentStatus') }}:</label>
                <select id="currencyFilter" v-model="filters.paid" class="invoice-input" @change="handleFilterChange">
                    <option v-for="(status, index) in [true, false]" :key="index" :value="status">
                        {{ status ? $t('info.paid') : $t('info.unpaid') }}
                    </option>
                </select>
            </div>

            <div class="filter-group">
                <label for="currencyFilter">{{ $t('filters.invoiceCategory') }}:</label>
                <select
                    id="invoiceCategory"
                    v-model="filters.invoice_category_id"
                    class="invoice-input"
                    @change="handleFilterChange"
                >
                    <option
                        v-for="category in invoiceCategories"
                        :key="category.attributes.id"
                        :value="category.attributes.id"
                    >
                        {{ category.attributes.name }}
                    </option>
                </select>
            </div>

            <div class="filter-group">
                <label for="tagFilter">{{ $t('filters.tags') }}:</label>
                <TagMultiplyFilter
                    :availableTags="availableTags"
                    :selectedInvoiceTags="filters.tags"
                    @update:selectedTags="handleTagFilterChange"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import Calendar from '@/components/Calendar.vue';
    import TagMultiplyFilter from './TagMultiplyFilter';
    import TagService from '@/modules/tags/services/tag.service';
    import { mapGetters } from 'vuex';

    export default {
        components: {
            TagMultiplyFilter,
            Calendar,
        },
        props: {
            showFiltersPanel: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                invoices: [],
                invoiceCategories: [],
                filters: {
                    amount_min_cents: '',
                    amount_max_cents: '',
                    currency: '',
                    tags: [],
                    paid: '',
                    invoice_category_id: '',
                },
                sortOrder: 'date',
                sortDirection: 'asc',
                tagService: new TagService(),
                availableTags: [],
                syncInvoiceGraph: false,
            };
        },
        created() {
            this.fetchTags();
            this.fetchInvoiceCategories();
        },
        computed: {
            ...mapGetters('currency', ['currencies']),
        },

        methods: {
            async fetchTags() {
                try {
                    const { data } = await this.tagService.getWithFilters({ disable_pagy: true, accounting: true });
                    this.availableTags = data.data;
                } catch (error) {
                    console.error('Error fetching invoices:', error);
                }
            },
            async fetchInvoiceCategories() {
                this.invoiceCategories = await this.$store.dispatch('invoiceCategory/getInvoiceCategories');
            },
            handleDateFilterChange(params) {
                this.filters.startDate = params.start;
                this.filters.endDate = params.end;
                this.handleFilterChange();
            },
            handleTagFilterChange(params) {
                this.filters.tags = params;
                this.handleFilterChange();
            },
            handleFilterChange() {
                if (this.syncInvoiceGraph) {
                    this.$emit('sync-graph-scope', true);
                }
                this.syncInvoiceGraph = true;
                this.$emit('fetch-with-filters', this.filters);
            },
            clearFilters() {
                this.filters = {
                    amount_min_cents: '',
                    amount_max_cents: '',
                    currency: '',
                    tags: [],
                    paid: '',
                    invoice_category_id: '',
                };
                this.$emit('scope-by-default');
                this.handleFilterChange();
            },
        },
    };
</script>

<style scoped lang="scss">
    .amount-range {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .filter-group {
        width: 99%;
    }
    .invoice-input {
        width: 99%;
        padding: 10px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
        transition: border-color 0.3s, box-shadow 0.3s;

        &:focus {
            border-color: #007bff;
            box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
        }

        .theme-dark & {
            background-color: #555;
            color: #ffa500;
            border-color: #444;

            &:focus {
                border-color: #66afe9;
                box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
            }
        }
    }

    .filter-panel {
        margin-bottom: 20px;
        padding: 20px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        .theme-dark & {
            background-color: #444;
            color: #ffa500;
        }
    }

    .filter-header {
        display: flex;
        margin-bottom: 10px;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .toggle-view {
        cursor: pointer;
        color: #007bff;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .toggle-view:hover {
        color: #0056b3;

        .theme-dark &:hover {
            color: #ffd700;
        }
    }

    .clear-filters {
        display: flex;
        justify-content: flex-end;
        margin-top: 15px;
    }

    .filters {
        display: grid;
        align-items: center;
        gap: 20px;
        margin-bottom: 10px;
        .theme-dark & {
            color: #ffa500;
        }
    }

    .mr-4 {
        margin-right: 4px;
    }

    ::v-deep .input {
        width: 100% !important;
        padding: 8px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 5px;

        .theme-dark & {
            background-color: #444;
            color: #ffa500;
            border-color: #666;
        }
    }

    @media (max-width: 768px) {
        .filter-panel {
            padding: 10px;
            margin-bottom: 10px;
            box-shadow: none;
            border-radius: 5px;
        }

        .filter-header {
            justify-content: center;
            margin-bottom: 5px;
        }

        .filters {
            flex-direction: column;
            gap: 10px;
        }

        .invoice-input {
            width: 100%;
            min-width: auto !important;
            padding: 8px;
        }

        .amount-range {
            flex-direction: column;
            align-items: stretch;
            gap: 10px;
        }

        .__symbol {
            text-align: center;
        }

        .clear-filters {
            justify-content: center;
            margin-top: 10px;
        }

        button {
            width: 100%;
        }

        ::v-deep .datepicker-wrapper,
        .at-select__dropdown,
        .at-select__dropdown--bottom {
            max-width: 273px;
            width: 99%;
        }

        ::v-deep .mx-range-wrapper {
            display: block;
        }
    }
</style>
