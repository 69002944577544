<template>
    <div class="additional-elements">
        <TaskContainer
            v-if="tasks"
            :tasks="tasks"
            :selectedTaskID="selectedTaskID"
            @task-selected="handleSelectedTask"
        />
        <TagsContainer
            v-if="tags"
            :tags="tags.general"
            :selectedTagIDs="selectedTagIDs"
            @tag-selected="handleSelectedTags"
        />
        <AdditionalPlans :selectedBillable="selectedBillable" @billable-status="handleBillable" />
    </div>
</template>

<script>
    import TaskContainer from './TaskContainer.vue';
    import TagsContainer from './TagsContainer.vue';
    import AdditionalPlans from './AdditionalPlans.vue';
    import { mapGetters } from 'vuex';

    export default {
        name: 'AdditionalElements',
        props: {
            selectedTaskID: {
                type: String,
                default: null,
            },
            selectedTagIDs: {
                type: Array,
                default: () => [],
            },
            selectedBillable: {
                type: Boolean,
                default: false,
            },
        },
        components: {
            TaskContainer,
            TagsContainer,
            AdditionalPlans,
        },
        computed: {
            ...mapGetters('task', ['tasks']),
            ...mapGetters('tag', ['tags']),
        },
        methods: {
            handleSelectedTask(task) {
                this.$emit('task-selected', task);
            },

            handleSelectedTags(tags) {
                this.$emit('tag-selected', tags);
            },

            handleBillable(status) {
                this.$emit('billable-selected', status);
            },
        },
    };
</script>
