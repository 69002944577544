<template>
    <div v-if="visible" class="modal-overlay">
        <div class="modal-content">
            <div class="modal-header">
                <h4>{{ $t('components.createStatusModal.title') }}</h4>
                <button class="close-button" @click="$emit('close')">&#x2715;</button>
            </div>
            <div class="modal-body">
                <div class="form-group" :class="{ 'has-error': errors.name }">
                    <label for="statusName">{{ $t('components.createStatusModal.nameLabel') }}</label>
                    <input
                        id="name"
                        v-model="name"
                        type="text"
                        :placeholder="$t('components.createStatusModal.namePlaceholder')"
                        @input="clearError('name')"
                    />
                    <span v-if="errors.name" class="error-message">{{ errors.name }}</span>
                </div>
                <div class="form-group">
                    <label for="statusDescription">{{ $t('components.createStatusModal.descriptionLabel') }}</label>
                    <textarea
                        id="statusDescription"
                        v-model="description"
                        :placeholder="$t('components.createStatusModal.descriptionPlaceholder')"
                        @input="clearError('description')"
                    ></textarea>
                </div>
                <div class="form-group" :class="{ 'has-error': errors.color }">
                    <color-input :value="color" @change="handleColorInput"></color-input>
                </div>
                <div class="form-actions">
                    <button class="at-btn at-btn--primary at-btn--large action-button" @click="handleSubmit">
                        <i class="icon icon-save"></i> {{ $t('components.createStatusModal.buttons.save') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ColorInput from '../../../components/ColorInput.vue';
    import StatusService from '../../Statuses/services/statuse.service';
    export default {
        components: { ColorInput },
        props: {
            visible: Boolean,
        },
        data() {
            return {
                name: '',
                description: '',
                color: this.setRandomColor(),
                errors: {
                    name: null,
                },
                service: new StatusService(),
            };
        },
        methods: {
            clearError(field) {
                this.errors[field] = null;
            },

            setRandomColor() {
                return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
            },
            async handleSubmit() {
                if (!this.name) {
                    this.errors.name = this.$t('components.createStatusModal.errors.name');
                }

                if (Object.values(this.errors).some(error => error)) {
                    return;
                }

                try {
                    await this.createStatus();
                    this.$emit('close');
                } catch (error) {
                    console.error('Error creating status', error);
                }
            },
            async createStatus() {
                const payload = {
                    status: {
                        name: this.name,
                        description: this.description,
                        color: this.color,
                    },
                };
                try {
                    const res = await this.service.save(payload);

                    if (res.status == 201) {
                        this.$Notify({
                            type: 'success',
                            title: this.$t('notification.save.success.title'),
                            message: this.$t('notification.save.success.message'),
                        });
                    }
                } catch (error) {
                    console.log('Error Create request Status', error);
                }
            },
            handleColorInput(color) {
                this.color = color;
            },
        },
    };
</script>

<style scoped lang="scss">
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }
    .modal-content {
        max-width: 450px;
        width: 400px;
        margin: 50px auto;
        padding: 25px;
        background: linear-gradient(135deg, #f7f9fc, #e2ebf0);
        border-radius: 12px;
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
        font-family: 'Arial', sans-serif;

        .theme-dark & {
            background: #222;
            color: #ffa500;
            box-shadow: 0 12px 24px rgba(0, 0, 0, 0.5);
        }
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
    }

    .form-group {
        margin-top: 10px;
    }

    label {
        display: block;
        font-weight: bold;
        color: #555;
        margin-bottom: 8px;

        .theme-dark & {
            color: #ffa500;
        }
    }

    textarea,
    input[type='text'] {
        width: 100%;
        padding: 12px 16px;
        font-size: 15px;
        border: 1px solid #ddd;
        border-radius: 8px;
        background-color: #fff;
        transition: border-color 0.3s, box-shadow 0.3s;

        .theme-dark & {
            background-color: #333;
            border-color: #555;
            color: #ffa500;
        }

        &:focus {
            border-color: #4caf50;
            outline: none;
            box-shadow: 0 0 10px rgba(76, 175, 80, 0.2);

            .theme-dark & {
                border-color: #ffa500;
                box-shadow: 0 0 10px rgba(255, 165, 0, 0.4);
            }
        }
    }

    .form-group.has-error input {
        border-color: #e74c3c;

        .theme-dark & {
            border-color: #ff4c4c;
        }
    }

    .error-message {
        color: #e74c3c;
        font-size: 12px;
        margin-top: 5px;

        .theme-dark & {
            color: #ff4c4c;
        }
    }

    .close-button {
        background: none;
        border: none;
        font-size: 20px;
        cursor: pointer;
    }
</style>
