import TagService from '../../modules/tags/services/tag.service';

const state = {
    tags: {
        general: null,
        accounting: null,
    },
    service: null,
};

const getters = {
    tags: s => s.tags,
    service: s => s.service,
};

const mutations = {
    setGeneralTagsData(s, tagsData) {
        s.tags.general = tagsData;
    },
    setAccountingTagsData(s, tagsData) {
        s.tags.accounting = tagsData;
    },
    setService(s, service) {
        s.service = service;
    },
};

const actions = {
    init(context) {
        context.commit('setService', new TagService());
    },
    setGeneralTagsData({ commit }, tags) {
        commit('setGeneralTagsData', tags);
    },
    async getGeneralTags({ dispatch, getters }) {
        let tags = getters.tags.general;
        if (!tags) {
            await dispatch('fetchGeneralTagsData');
            tags = getters.tags;
        }
        return tags;
    },
    async fetchGeneralTagsData({ commit, getters }) {
        const { service } = getters;
        if (service) {
            const { data } = await service.getAll({ disable_pagy: true });
            const tags = data.data;
            commit('setGeneralTagsData', tags);
            return tags;
        }
        return null;
    },

    setAccountingTagsData({ commit }, tags) {
        commit('setAccountingTagsData', tags);
    },
    async getAccountingTags({ dispatch, getters }) {
        let tags = getters.tags.accounting;
        if (!tags) {
            await dispatch('fetchAccountingTagsData');
            tags = getters.tags.accounting;
        }
        return tags;
    },
    async fetchAccountingTagsData({ commit, getters }) {
        const { service } = getters;
        if (service) {
            const { data } = await service.getAll({ disable_pagy: true, accounting: true });
            const tags = data.data;
            commit('setAccountingTagsData', tags);
            return tags;
        }
        return null;
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
