<template>
    <div class="document-page">
        <div class="header">
            <h1>Documents</h1>
        </div>

        <div class="controls">
            <div class="status-buttons">
                <button
                    v-for="status in approvalStatuses"
                    :key="status"
                    :class="['toggle-button', { active: currentStatus === status }]"
                    class="at-btn at-btn--primary at-btn--large"
                    @click="setApprovalStatus(status)"
                >
                    {{ $t(`documents.list.buttons.${status}`) }}
                </button>
            </div>

            <div class="archive-buttons">
                <button class="archive-button at-btn at-btn--primary at-btn--large" @click="toggleArchivedView">
                    {{ showArchived ? $t('documents.list.buttons.hide') : $t('documents.list.buttons.show') }}
                </button>

                <button
                    class="action-button at-btn at-btn--primary at-btn--large"
                    @click="$router.push({ name: 'document.new' })"
                >
                    {{ $t('documents.list.buttons.create') }}
                </button>
            </div>
        </div>

        <div class="document-type-title">
            <h2>
                {{ showArchived ? 'Archived ' : '' }}
                {{ currentStatus.charAt(0).toUpperCase() + currentStatus.slice(1) }} Documents
            </h2>
        </div>

        <div v-if="documents.length" class="document-list">
            <ul class="documents">
                <li v-for="document in documents" :key="document.attributes.id" class="document-item">
                    <div class="details">
                        <p>
                            <strong>{{ $t('documents.list.name') }}:</strong> {{ document.attributes.name }}
                        </p>
                        <p>
                            <strong>{{ $t('documents.list.main_company') }}:</strong>
                            {{ document.attributes.company.attributes.name }}
                        </p>
                        <p>
                            <strong>{{ $t('documents.list.version') }}:</strong> {{ document.attributes.version }}
                        </p>
                        <p v-if="document.attributes.partner_companies.length">
                            <strong>{{ $t('documents.list.partner_companies') }}:</strong>
                        </p>
                        <ul class="partner-companies">
                            <li v-for="partner in document.attributes.partner_companies" :key="partner.attributes.id">
                                {{ partner.attributes.company_name }} -
                                <strong>{{ $t('documents.list.status') }}:</strong>
                                {{ partner.attributes.company_approval_status || 'Pending' }}
                            </li>
                        </ul>
                    </div>
                    <div class="actions">
                        <button
                            class="view-button at-btn crud__control-items__item at-btn--primary at-btn--large"
                            @click="viewDocument(document)"
                        >
                            {{ $t('documents.list.buttons.view') }}
                        </button>
                    </div>
                </li>
            </ul>
        </div>

        <div v-else class="no-document-card">
            <h1>{{ $t('documents.list.not_found') }}</h1>
            <p>{{ $t('documents.list.not_found_message') }}</p>
        </div>
    </div>
</template>

<script>
    import DocumentService from '../../services/document.service';
    import { mapGetters } from 'vuex';
    import ClientService from '../../../Client/services/client.service';

    export default {
        name: 'DocumentListPage',
        data() {
            return {
                documents: [],
                client: null,
                showArchived: false,
                currentStatus: 'pending',
                approvalStatuses: ['pending', 'approved', 'rejected'],
                clientService: new ClientService(),
                service: new DocumentService(),
            };
        },
        computed: {
            ...mapGetters('company', ['company']),
        },
        async created() {
            await this.fetchClient();
            await this.fetchDocuments();
        },
        methods: {
            viewDocument(document) {
                this.$router.push({ name: 'document.view', params: { document_id: document.attributes.id } });
            },
            toggleArchivedView() {
                this.showArchived = !this.showArchived;
                this.fetchDocuments();
            },
            setApprovalStatus(status) {
                this.currentStatus = status;
                this.fetchDocuments();
            },
            async fetchClient() {
                try {
                    const { data } = await this.clientService.getItem(this.$route.params.id);
                    this.client = data.data.attributes;
                } catch (error) {
                    console.log('Error fetching client:', error);
                }
            },
            async fetchDocuments() {
                try {
                    const { data } = await this.service.getAll({
                        archived: this.showArchived,
                        status: this.currentStatus,
                        client_company_id: this.client.company_id,
                        filter: 'client',
                    });
                    this.documents = data.data;
                } catch (error) {
                    console.log('Error fetching documents:', error);
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .document-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #f4f6f8;
        padding: 20px;
        border-radius: 5px;
    }

    .header {
        width: 100%;
        max-width: 700px;
        margin-bottom: 20px;
        text-align: center;
    }

    .controls {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        margin-bottom: 20px;
    }

    .status-buttons {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
    }

    .archive-buttons {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: center;
    }

    .toggle-button.active {
        color: #ffa500;

        .theme-dark & {
            color: #c4c4cf;
        }
    }

    .document-type-title h2 {
        font-size: 1.5rem;
        margin-bottom: 10px;
        color: #333;
        text-align: center;
    }

    .document-list {
        max-width: 700px;
        width: 100%;
    }

    .documents {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .document-item {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        margin-bottom: 10px;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s;
    }

    .document-item:hover {
        transform: scale(1.02);
    }

    .no-document-card {
        text-align: center;
        color: #777;
    }

    .partner-companies {
        margin-top: 10px;
        padding-left: 20px;
    }

    .theme-dark .document-page {
        background-color: #2b2b2b;
    }

    .theme-dark .document-type-title h2 {
        color: #e0e0e0;
    }

    .theme-dark .archived-document-list {
        background-color: #3c3c3c;
        border-color: #444;
        color: #e0e0e0;
    }

    .theme-dark .document-item {
        background-color: #333;
    }

    .theme-dark .document-item:hover {
        background: #444;
        box-shadow: 0 8px 16px rgba(255, 255, 255, 0.1);
    }
</style>
