<template>
    <div class="invoice-list">
        <div class="additional-buttons">
            <div class="left-buttons">
                <button
                    v-if="isMobile"
                    class="at-btn crud__control-items__item at-btn--primary at-btn--default at-btn--large"
                    @click="openFiltersPanel"
                >
                    {{ $t('actions.filters') }}
                </button>
                <button
                    v-else
                    class="at-btn crud__control-items__item at-btn--primary at-btn--default at-btn--large"
                    @click="openFiltersPanel"
                >
                    <font-awesome-icon :icon="['fas', 'filter']" />
                </button>

                <UploadModal :visible="true" :class="[{ hide: !isFiltersModalVisible }]" @close="closeFilterModal">
                    <FilterPanel
                        @fetch-with-filters="handleFetchWithFilters"
                        @scope-by-default="handleScopeByDefault"
                        @sync-graph-scope="handleGraphScope"
                    ></FilterPanel>
                </UploadModal>
            </div>
            <div class="right-buttons">
                <button
                    class="at-btn crud__control-items__item at-btn--primary at-btn--default at-btn--large"
                    @click="openUploadModal"
                >
                    {{ $t('actions.upload') }}
                </button>
            </div>

            <UploadModal :visible="isModalVisible" @close="closeModal">
                <UploadForm @upload-success="handleUploadSuccess" />
            </UploadModal>
        </div>

        <ul class="invoice-list-view">
            <li>
                <div class="invoice-header">
                    <input type="checkbox" @change="toggleSelectAll" />
                    <span class="header-item"
                        ><a>{{ $t('filters.tags') }}</a></span
                    >
                    <span class="header-item">
                        <a @click="changeSortType('date')">
                            {{ $t('filters.date') }}
                            {{ sortOrder === 'date' ? (sortDirection === 'asc' ? '↑' : '↓') : '' }}
                        </a>
                    </span>
                    <span class="header-item">
                        <a @click="changeSortType('payment_date')">
                            {{ $t('filters.paymentDate') }}
                            {{ sortOrder === 'payment_date' ? (sortDirection === 'asc' ? '↑' : '↓') : '' }}
                        </a>
                    </span>
                    <span class="header-item">
                        <a @click="changeSortType('amount_cents')">
                            {{ $t('filters.amount') }}
                            {{ sortOrder === 'amount_cents' ? (sortDirection === 'asc' ? '↑' : '↓') : '' }}
                        </a>
                    </span>
                    <span class="header-actions">{{ $t('filters.actions') }}</span>
                </div>
            </li>
            <li
                v-for="invoice in invoices"
                :key="invoice.id"
                class="invoice-row"
                :class="{ selected: selectedInvoices.includes(invoice.id) }"
            >
                <div
                    class="invoice-item"
                    :class="paymentColorStatus(invoice)"
                    @click="toggleInvoiceSelection(invoice.id)"
                >
                    <input v-model="selectedInvoices" type="checkbox" :value="invoice.id" />
                    <span class="item-data tag-data">
                        <div class="tags-container">
                            <span
                                v-if="invoice.attributes.tags.length > 0"
                                class="tag-first"
                                @mouseleave="showTagPopup = false"
                                @click="toggleTagPopup(invoice, !invoice.showTagPopup)"
                            >
                                {{
                                    isMobile
                                        ? invoice.attributes.tags[0].name
                                        : truncate(invoice.attributes.tags[0].name)
                                }}
                                <span v-if="invoice.attributes.tags.length > 1" class="tag-count">
                                    (+{{ invoice.attributes.tags.length - 1 }})
                                </span>
                            </span>

                            <span
                                v-else
                                @mouseleave="showTagPopup = false"
                                @click="toggleTagPopup(invoice, !invoice.showTagPopup)"
                                >__</span
                            >
                            <TagSelectorModal
                                :showModal="invoice.showTagPopup"
                                :initialSelectedTags="invoice.attributes.tags"
                                :invoice="invoice"
                                @update-tags="updateInvoiceTags"
                                @mouseleave="toggleTagPopup(invoice, false)"
                            />
                        </div>
                    </span>
                    <span class="item-data">{{ invoice.attributes.date }}</span>
                    <span class="item-data">{{ invoice.attributes.payment_date }}</span>
                    <span class="item-data">{{ invoice.attributes.amount_format }}</span>

                    <div v-if="isMobile" class="invoice-actions">
                        <button
                            class="at-btn crud__control-items__item at-btn--primary at-btn--default at-btn--large"
                            @click.stop="showInvoice(invoice)"
                        >
                            {{ $t('actions.show') }}
                        </button>
                        <button
                            class="at-btn crud__control-items__item at-btn--primary at-btn--default at-btn--large"
                            @click.stop="editInvoice(invoice)"
                        >
                            {{ $t('actions.edit') }}
                        </button>
                        <TagSelector
                            :initialSelectedTags="invoice.attributes.tags"
                            :invoiceId="invoice.id"
                            @update-tags="updateInvoiceTags"
                        />
                        <!-- <button class="tags" @click="invoice.showTagPopup = true">1</button>
                        <TagSelectorModal
                            :showModal="invoice.showTagPopup"
                            :initialSelectedTags="invoice.attributes.tags"
                            :invoice="invoice"
                            @update-tags="updateInvoiceTags"
                            @mouseleave="toggleTagPopup(invoice, false)"
                        /> -->
                    </div>
                    <div v-else class="invoice-actions">
                        <at-dropdown :trigger="'click'" @on-dropdown-command="onDropdownCommand($event, invoice)">
                            <button><i class="icon icon-more-vertical"></i></button>
                            <at-dropdown-menu slot="menu" class="dropdown-menu">
                                <at-dropdown-item v-for="(item, index) in dropdownItems" :key="index" :name="item.name">
                                    {{ item.text }}
                                </at-dropdown-item>
                            </at-dropdown-menu>
                        </at-dropdown>
                        <TagSelectorModal
                            :showModal="invoice.showTagPopup"
                            :initialSelectedTags="invoice.attributes.tags"
                            :invoice="invoice"
                            @update-tags="updateInvoiceTags"
                            @mouseleave="toggleTagPopup(invoice, false)"
                        />
                    </div>
                </div>
            </li>
        </ul>

        <FloatingFooter
            :visible="selectedInvoices.length > 0"
            :intervals="selectedInvoices.map(id => invoices.find(invoice => invoice.id === id))"
            :disabledButtons="disabledButtons"
            @delete="deleteSelected"
            @close="clearSelection"
        />
    </div>
</template>

<script>
    import InvoiceService from '../services/invoice.service';
    import TagService from '@/modules/tags/services/tag.service';
    import FloatingFooter from './FloatingFooter.vue';
    import TagSelector from './TagSelector.vue';
    import TagSelectorModal from './TagSelectorModal.vue';
    import { debounce } from 'lodash';
    import UploadForm from '../components/UploadForm.vue';
    import UploadModal from '../components/UploadModal.vue';
    import FilterPanel from '../components/FilterPanel.vue';

    export default {
        components: {
            FloatingFooter,
            TagSelector,
            TagSelectorModal,
            UploadForm,
            UploadModal,
            FilterPanel,
        },
        props: {
            ownInvoices: {
                type: Boolean,
                reqired: true,
            },
        },
        data() {
            return {
                invoices: [],
                filters: {
                    startDate: new Date(),
                    endDate: new Date(),
                    amount_cents: '',
                    currency: '',
                    tags: [],
                },
                sortOrder: 'date',
                sortDirection: 'desc',
                service: new InvoiceService(),
                tagService: new TagService(),
                page: +(this.$route.query.page || 1),
                totalItems: 0,
                itemsPerPage: 10,
                queryParams: {
                    page: +(this.$route.query.page || 1),
                },
                displayMode: '',

                isModalVisible: false,
                isFiltersModalVisible: false,
                showTagPopup: false,

                loadMore: true,
                selectedInvoices: [],
                disabledButtons: false,
                currentInvoiceTags: [],
                currentInvoiceId: null,
                isMobile: false,

                syncGraphScope: false,

                currentDate: new Date().toISOString().split('T')[0],

                dropdownItems: [
                    { name: 'show', text: this.$t('actions.show') },
                    { name: 'edit', text: this.$t('actions.edit') },
                    { name: 'add-tags', text: this.$t('actions.addTags') },
                ],
            };
        },
        async created() {
            await this.fetchFilteredInvoices();
            this.handleResize();
            window.addEventListener('scroll', this.handleScroll);
            window.addEventListener('resize', this.handleResize);
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        computed: {
            truncate() {
                const length = 3;
                return value => (value.length >= length ? value.substring(0, length) + '...' : value);
            },
            paymentColorStatus() {
                return ({ attributes }) => {
                    if (attributes.paid) return;
                    if (new Date() < new Date(attributes.payment_date)) return 'processing';
                    if (this.currentDate == attributes.payment_date) return 'last-payment-day';

                    return 'unpaid';
                };
            },
        },
        methods: {
            onDropdownCommand(action, invoice) {
                switch (action) {
                    case 'show':
                        this.showInvoice(invoice);
                        break;
                    case 'edit':
                        this.editInvoice(invoice);
                        break;
                    case 'add-tags':
                        invoice.showTagPopup = true;
                        break;
                }
            },
            handleResize() {
                this.isMobile = window.innerWidth >= 650;
            },
            openUploadModal() {
                this.isModalVisible = true;
            },
            openFiltersPanel() {
                this.isFiltersModalVisible = true;
            },
            closeModal() {
                this.isModalVisible = false;
            },
            closeFilterModal() {
                this.isFiltersModalVisible = false;
            },
            handleUploadSuccess() {
                this.closeModal();
            },
            toggleInvoiceSelection(invoiceId) {
                const index = this.selectedInvoices.indexOf(invoiceId);
                if (index === -1) {
                    this.selectedInvoices.push(invoiceId);
                } else {
                    this.selectedInvoices.splice(index, 1);
                }
            },
            toggleTagPopup(invoice, show) {
                invoice.showTagPopup = show;
            },

            sortType(type) {
                this.sortDirection = this.sortDirection == 'asc';
            },
            changeSortType(type) {
                this.page = 1;
                this.loadMore = true;
                if (this.sortOrder === type) {
                    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
                } else {
                    this.sortOrder = type;
                    this.sortDirection = 'asc';
                }
                this.fetchFilteredInvoices();
            },

            fetchFilteredInvoices: debounce(async function () {
                try {
                    const res = await this.service.getWithFilters({
                        ...this.filters,
                        startDate: this.filters.startDate,
                        endDate: this.filters.endDate,
                        sortOrder: this.sortOrder,
                        sortDirection: this.sortDirection,
                        own: this.ownInvoices,
                    });
                    const { data, pagination } = res.data;
                    this.totalItems = pagination.total;
                    this.page = pagination.page;
                    this.itemsPerPage = 1;
                    this.invoices = data.map(invoice => ({
                        ...invoice,
                        showTagPopup: false,
                    }));

                    if (this.syncGraphScope) {
                        this.$emit('invoice-scope', this.invoices);
                    }
                } catch (error) {
                    console.error('Error fetching filtered invoices:', error);
                }
            }, 500),
            showInvoice(invoice) {
                this.$router.push({ path: `view/${invoice.id}` });
            },
            editInvoice(invoice) {
                this.$router.push({ path: `edit/${invoice.id}` });
            },
            toggleDisplayMode() {
                this.displayMode = this.displayMode === 'card' ? 'table' : 'card';
                localStorage.displayMode = this.displayMode;
            },
            async onPageChange(page) {
                await this.handlePageChange(page);
            },
            handleFetchWithFilters(filters) {
                this.filters = filters;
                this.fetchFilteredInvoices();
            },
            handleFetchWithSort(params) {
                this.sortOrder = params.order;
                this.sortDirection = params.direction;
                this.fetchFilteredInvoices();
            },
            handlePageChange(page) {
                if (this.queryParams.page === page) {
                    return;
                }
                this.queryParams.page = page;
                return this.fetchFilteredInvoices();
            },

            handleScopeByDefault() {
                this.syncGraphScope = false;
                this.$emit('scope-by-default');
            },
            handleGraphScope() {
                this.loadMore = true;
                this.syncGraphScope = true;
            },
            async handleScroll() {
                const bottomOfWindow = window.scrollY + window.innerHeight >= document.documentElement.scrollHeight;
                if (bottomOfWindow) {
                    this.page += 1;
                    await this.loadMoreInvoices();
                }
            },
            async loadMoreInvoices() {
                try {
                    if (this.loadMore) {
                        const res = await this.service.getWithFilters({
                            ...this.filters,
                            page: this.page,
                            sortOrder: this.sortOrder,
                            sortDirection: this.sortDirection,
                            own: this.ownInvoices,
                        });

                        if (res.status === 204) {
                            this.loadMore = false;
                            return;
                        }

                        const { data } = res.data;
                        const newInvoices = data.map(invoice => ({
                            ...invoice,
                            showTagPopup: false,
                        }));

                        this.invoices.push(...newInvoices);

                        this.invoices = this.invoices.filter(
                            (invoice, index, self) => index === self.findIndex(t => t.id === invoice.id),
                        );

                        if (this.syncGraphScope) {
                            this.$emit('invoice-scope', this.invoices);
                        }
                    }
                } catch (error) {
                    console.error('Error loading more invoices:', error);
                }
            },
            toggleSelectInvoice(invoiceId) {
                const index = this.selectedInvoices.indexOf(invoiceId);
                if (index >= 0) {
                    this.selectedInvoices.splice(index, 1);
                } else {
                    this.selectedInvoices.push(invoiceId);
                }
            },
            toggleSelectAll(event) {
                if (event.target.checked) {
                    this.selectedInvoices = this.invoices.map(invoice => invoice.id);
                } else {
                    this.selectedInvoices = [];
                }
            },
            async updateInvoiceTags(invoiceId, tags) {
                try {
                    await this.tagService.addTags({
                        object_id: invoiceId,
                        object_model_name: 'invoice',
                        tag_ids: tags,
                    });
                    this.fetchFilteredInvoices();
                } catch (error) {
                    console.error('Error updating tags:', error);
                }
            },
            async deleteSelected() {
                try {
                    await this.service.bulkDestroy({
                        invoice_ids: this.selectedInvoices,
                    });
                } catch (error) {
                    console.error('Error destroy tags:', error);
                }
                this.selectedInvoices = [];
                this.fetchInvoices();
            },
            clearSelection() {
                this.selectedInvoices = [];
                this.disabledButtons = false;
            },
        },
    };
</script>

<style scoped lang="scss">
    input {
        display: block;
    }
    h2 {
        color: #333;
        text-align: center;
        margin-bottom: 20px;

        .theme-dark & {
            color: #ffa500;
        }
    }

    a {
        color: #1f1f1f;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .tags-container {
        position: relative;
        display: inline-block;
        cursor: pointer;
    }

    .tag-first {
        display: flex;
        position: relative;
    }

    .unpaid {
        background-color: #f28b82;
        color: #b71c1c;

        .theme-dark & {
            background-color: #8b3c3c;
            color: #f1c4c4;
        }
    }

    .processing {
        background-color: #fff176;
        color: #f57f17;

        .theme-dark & {
            background-color: #be8f00;
            color: #fff8b3;
        }
    }

    .last-payment-day {
        background-color: #ffb74d;
        color: #e65100;

        .theme-dark & {
            background-color: #994f00;
            color: #ffe0b2;
        }
    }

    .at-dropdown-menu__item {
        color: #1f1f1f;

        .theme-dark & {
            background-color: #333;
            color: #ffa500;

            &:hover {
                background-color: #555;
                color: #c4c4cf;
            }
        }
    }

    .invoice-row.selected {
        .invoice-item {
            &.unpaid {
                background-color: #8b3c3c;
                color: #f1c4c4;

                .theme-dark & {
                    background-color: #f28b82;
                    color: #b71c1c;
                }
            }

            &.processing {
                background-color: #be8f00;
                color: #fff8b3;

                .theme-dark & {
                    background-color: #fff176;
                    color: #f57f17;
                }
            }

            &.last-payment-day {
                background-color: #994f00;
                color: #ffe0b2;

                .theme-dark & {
                    background-color: #ffb74d;
                    color: #e65100;
                }
            }
        }
    }

    .tag-popup {
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        position: absolute;
        top: 25px;
        left: 0;
        background-color: #fff;
        border: 1px solid #ccc;
        padding: 5px;
        border-radius: 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        z-index: 1000;
    }

    .theme-dark .tag-popup {
        background-color: #333;
        color: #ffa500;
        border-color: #555;
    }

    .tag-count {
        margin-left: 4px;
        color: #999;
    }

    .invoice-list {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        position: relative;

        .theme-dark & {
            background-color: #333;
            color: #ffa500;
        }
    }

    .invoice-details {
        margin-bottom: 20px;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .invoice-details div {
        margin-bottom: 10px;
    }

    .invoice-actions {
        display: flex;
        gap: 10px;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .invoice-list-view {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .invoice-header {
        display: flex;
        flex: 1 1 calc(33.33% - 20px);
        align-items: center;
        font-weight: bold;
        border-bottom: 1px solid #ccc;

        .theme-dark & {
            border-color: #555;
        }
    }

    .header-item {
        padding: 10px;
        text-align: left;
        flex: 1 1 calc(33.33% - 20px);
    }

    .header-actions {
        max-width: 174.5px;
        width: 99%;
    }

    .invoice-row {
        display: flex;
        align-items: center;
    }

    .invoice-row:hover {
        background-color: #f0f0f0;
        .theme-dark & {
            background-color: rgba(48, 213, 200, 0.3);
        }
    }

    .invoice-item {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #eee;
        transition: background-color 0.3s;
        padding: 0px 5px 0px 5px;
        flex: 1 1 calc(33.33% - 20px);

        .theme-dark & {
            border-color: #555;
        }
    }

    .tag-circle {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 5px;
    }

    .invoice-actions {
        display: flex;
        align-items: center;
    }

    .item-data {
        flex: 1;
        padding: 10px;
    }

    .mr-4 {
        margin-right: 4px;
    }

    .additional-buttons {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .left-buttons {
        display: flex;
        gap: 20px;
    }

    .footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 10px;
        background-color: rgba(255, 255, 255, 0.9);
        color: #333;
        text-align: center;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);

        .theme-dark & {
            background-color: rgba(51, 51, 51, 0.9);
            color: #ffa500;
        }
    }

    .delete-btn {
        background-color: #dc3545;
        width: 55px;
        color: #fff;
        border: none;
        padding: 8px 12px;
        font-size: 14px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        .theme-dark & {
            background-color: #922529;
        }
    }

    .delete-btn:hover {
        background-color: #c82333;

        .theme-dark &:hover {
            background-color: #a71d2a;
        }
    }
    @media (max-wisth: 1400px) {
        .invoice-list {
            width: 100%;
        }

        .invoice-cards {
            display: flex;
        }

        .invoice-card {
            flex: 1 1 calc(50% - 10px);
        }

        .invoice-list-view .invoice-header,
        .invoice-list-view .invoice-item {
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
        }

        .invoice-list-view .header-item,
        .invoice-list-view .item-data {
            width: 99%;
        }
    }

    @media (max-width: 768px) {
        .filters {
            flex-direction: column;
            gap: 15px;
        }

        .invoice-card {
            flex: 1 1 calc(50% - 20px);
        }
    }

    @media (max-width: 650px) {
        .header-actions {
            max-width: 55px;
            width: 99%;
        }

        button {
            margin-right: 2px;
        }
    }

    @media (max-width: 550px) {
        input {
            display: none;
        }
        .right-buttons {
            display: none;
        }
        .invoice-row {
            display: block;
            margin-bottom: 15px;
            padding: 10px;
            background-color: #fff;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            border-radius: 8px;

            .theme-dark & {
                background-color: #444;
                color: #ffa500;
            }
        }

        .invoice-item {
            border-bottom: none;
            border-radius: 5px;
        }

        .invoice-header {
            border-bottom: none;
        }

        .item-data {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
        }

        .invoice-actions {
            text-align: left;
            max-width: 50px;
        }

        .invoice-row.selected {
            background-color: rgba(48, 213, 200, 0.3);
            cursor: pointer;
        }

        .invoice-item {
            cursor: pointer;
        }
    }
</style>
