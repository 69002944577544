<template>
    <div class="services">
        <div class="header" :class="{ hovered: isHovered }">
            <h1>{{ $t('page.currentSubscription.subscriptions') }}</h1>
            <button
                v-if="subscriptionPrices.length > 0 && company.subscription"
                class="back-button at-btn crud__control-items__item at-btn--primary at-btn--primary at-btn--large"
                @click="goBack"
            >
                {{ $t('page.buttons.back') }}
            </button>
        </div>
        <div class="service-list">
            <div v-if="subscriptionPrices.length > 0">
                <div
                    v-for="(plan, index) in subscriptionPrices"
                    :key="index"
                    class="service-card"
                    @mouseenter="isHovered = true"
                    @mouseleave="isHovered = false"
                >
                    <div class="card-header">
                        <h2>{{ plan.name }}</h2>
                    </div>
                    <p>{{ plan.description || $t('page.currentSubscription.noDescription') }}</p>
                    <div class="pricing">
                        <label for="period">{{ $t('page.currentSubscription.selectPeriod') }}</label>
                        <div class="selector-container">
                            <select
                                v-model="selectedPeriods[index]"
                                class="period-selector"
                                @change="updatePrice(index)"
                            >
                                <option v-for="(price, period) in plan.price" :key="period" :value="period">
                                    {{ $t(`period.${period}`) }}
                                </option>
                            </select>
                            <span class="selector-icon">
                                <font-awesome-icon :icon="['fas', 'chevron-down']" />
                            </span>
                        </div>
                    </div>

                    <div class="buttons">
                        <button
                            class="subscribe-button at-btn crud__control-items__item at-btn--primary at-btn--primary at-btn--large"
                            @click="openModal(plan, currentPrices[index])"
                        >
                            {{ $t('page.buttons.subscribe') }} - {{ formatPrice(currentPrices[index]) }}
                        </button>
                    </div>
                </div>
            </div>
            <div v-else class="no-data">{{ $t('page.currentSubscription.noData') }}</div>
        </div>

        <Modal v-if="showModal" :visible="showModal" :message="message" @close="closeModal" @confirm="prepareRequest" />
    </div>
</template>

<script>
    import SubscriptionService from '../services/subscription.service';
    import RevolutOrderService from '../services/revolut-order.service';
    import Modal from '../components/SubscriptionModal.vue';
    import { mapGetters } from 'vuex';

    export default {
        components: {
            Modal,
        },
        data() {
            return {
                isHovered: false,
                showModal: false,
                message: '',
                subscriptionPrices: [],
                selectedPeriods: [],
                currentPrices: [],
                currentSubscription: null,
                templeSubscriptionObj: null,
                companyData: null,
                service: new SubscriptionService(),
                order: new RevolutOrderService(),
                subscriptions: [],
            };
        },
        async created() {
            await this.fetchSubscriptions();
            await this.fetchCompanyData();
            if (this.companyData.subscription_prices) {
                await this.pushSubscriptionPrices();
            }
        },
        computed: {
            ...mapGetters('company', ['company']),
        },
        methods: {
            openModal(plan, price) {
                this.showModal = true;
                this.message = this.$t('page.modal.confirmSubscription');
                this.templeSubscriptionObj = { plan, price };
            },
            closeModal() {
                this.showModal = false;
                this.templeSubscriptionObj = null;
            },
            async fetchSubscriptions() {
                try {
                    const { data } = await this.service.getAll();
                    this.subscriptions = data.data;
                } catch (error) {
                    console.error(error);
                }
            },
            async fetchCompanyData() {
                try {
                    this.companyData = await this.$store.dispatch('company/fetchCompanySettings');
                } catch (error) {
                    console.error(error);
                }
            },
            calculateDates(period) {
                const startDate = new Date();
                let endDate = new Date(startDate);

                switch (period) {
                    case 'monthly':
                        endDate.setMonth(endDate.getMonth() + 1);
                        break;
                    case 'quarterly':
                        endDate.setMonth(endDate.getMonth() + 3);
                        break;
                    case 'yearly':
                        endDate.setFullYear(endDate.getFullYear() + 1);
                        break;
                    default:
                        console.warn('Unknown period:', period);
                }

                return {
                    start_date: startDate.toISOString().split('T')[0],
                    end_date: endDate.toISOString().split('T')[0],
                };
            },
            pushSubscriptionPrices() {
                this.subscriptionPrices = this.subscriptions.map(subscription => {
                    const subPrice = this.companyData.subscription_prices.find(
                        price => price.id === subscription.attributes.id,
                    );

                    return {
                        id: subscription.attributes.id,
                        name: subscription.attributes.name,
                        description: subscription.attributes.description,
                        price: subPrice.price,
                    };
                });

                this.initializePeriodsAndPrices();
            },
            initializePeriodsAndPrices() {
                this.subscriptionPrices.forEach((plan, index) => {
                    const defaultPeriod = Object.keys(plan.price)[0];
                    this.selectedPeriods[index] = defaultPeriod;
                    this.currentPrices[index] = plan.price[defaultPeriod];
                });
            },
            updatePrice(index) {
                const selectedPeriod = this.selectedPeriods[index];
                this.currentPrices[index] = this.subscriptionPrices[index].price[selectedPeriod];
            },
            prepareRequest() {
                const { plan, price } = this.templeSubscriptionObj;
                const selectedPeriod = this.selectedPeriods[this.subscriptionPrices.indexOf(plan)];
                const { start_date, end_date } = this.calculateDates(selectedPeriod);
                const formData = new FormData();
                formData.append('revolut_order[amount_cents]', price * 100);
                formData.append('revolut_order[amount_currency]', 'GBP');
                formData.append('revolut_order[subscription_id]', plan.id);
                formData.append('revolut_order[start_date]', start_date);
                formData.append('revolut_order[end_date]', end_date);
                this.subscribe(formData);

                this.closeModal();
            },
            formatPrice(price) {
                return new Intl.NumberFormat(this.$i18n.locale, {
                    style: 'currency',
                    currency: 'GBP',
                }).format(price);
            },
            async subscribe(form) {
                try {
                    const res = await this.order.subscribe(form);

                    this.$Notify({
                        type: 'success',
                        title: this.$t('notification.save.success.title'),
                        message: this.$t('notification.save.success.message'),
                    });

                    if (res.status == 201) {
                        const { checkout_url } = res.data.data.attributes;
                        window.open(checkout_url, '_blank');
                    }

                    if (res.status == 204) {
                        this.$router.push({ name: 'company.subscriptions.current' });
                    }
                } catch (error) {
                    console.error(error);
                }
            },
            goBack() {
                this.$router.go(-1);
            },
        },
    };
</script>

<style scoped lang="scss">
    .services {
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h1 {
                font-size: 26px;
                font-weight: bold;
                color: #2c3e50;
                .theme-dark & {
                    color: #ffa500;
                }
            }
        }

        .header {
            transition: margin-bottom 0.3s;

            &.hovered {
                margin-bottom: 20px;
            }
        }

        .service-card {
            padding: 20px;
            border-radius: 16px;
            background: linear-gradient(135deg, #f7f9fc, #e2ebf0);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            margin-bottom: 20px;
            transition: transform 0.3s, box-shadow 0.3s;

            &:hover {
                transform: translateY(-10px);
                box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
            }

            .theme-dark & {
                background: #333;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);

                &:hover {
                    box-shadow: 0 8px 16px rgba(255, 165, 0, 0.7);
                }
            }

            .card-header {
                margin-bottom: 15px;

                h2 {
                    font-size: 24px;
                    font-weight: bold;
                    color: #34495e;
                    .theme-dark & {
                        color: #ffa500;
                    }
                }
            }

            p {
                font-size: 16px;
                color: #7f8c8d;
                line-height: 1.5;

                .theme-dark & {
                    color: #bdc3c7;
                }
            }

            .pricing {
                margin-bottom: 20px;

                label {
                    font-size: 14px;
                    font-weight: 600;
                    color: #2c3e50;

                    .theme-dark & {
                        color: #ecf0f1;
                    }
                }

                .period-selector {
                    margin-top: 10px;
                    font-size: 16px;
                    padding: 10px;
                    width: 100%;
                    border: 2px solid #555;
                    border-radius: 8px;
                    background-color: #ecf0f1;
                    color: #2c3e50;
                    transition: border-color 0.3s, background-color 0.3s, color 0.3s;

                    &:focus {
                        outline: none;
                        border-color: #222;
                        background-color: #ffffff;
                        color: #34495e;
                    }

                    .theme-dark & {
                        background-color: #333;
                        color: #ffa500;
                        border-color: #555;

                        &:focus {
                            background-color: #222;
                            border-color: #ffa500;
                            color: #ffa500;
                        }
                    }
                }
            }

            .buttons {
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    .selector-container {
        position: relative;
        display: inline-block;
        width: 100%;
    }

    .selector-icon {
        position: absolute;
        top: 60%;
        right: 1rem;
        transform: translateY(-50%);
        pointer-events: none;
        color: #666;
    }
</style>
