import ResourceService from '@/services/resource.service';
import axios from 'axios';

export default class TimeIntervalService extends ResourceService {
    getItemRequestUri(id) {
        return `time-intervals/show?id=${id}`;
    }

    getItem(id) {
        return axios.get(this.getItemRequestUri(id));
    }

    getAll(filters = {}) {
        return axios.post('api/v1/time-intervals/list', filters);
    }

    save(data) {
        return axios.post('api/v1/time-intervals/create', data);
    }

    bulkEdit(data) {
        return axios.post('time-intervals/bulk-edit', data);
    }

    bulkDelete(data) {
        return axios.put('api/v1/time-intervals/bulk-remove', data);
    }

    deleteItem(id) {
        return axios.put('api/v1/time-intervals/remove', { id });
    }

    update(params, id) {
        return axios.patch(`api/v1/time-intervals/${id}`, params);
    }
}
