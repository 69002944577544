import CompanyService from '@/services/company.service.js';

const state = {
    company: null,
    service: null,
    settings: null,
};

const getters = {
    company: s => s.company,
    service: s => s.service,
    settings: s => s.settings,
};

const mutations = {
    setCompanyData(s, companyData) {
        s.company = companyData;
    },
    setService(s, service) {
        s.service = service;
    },
    setCompanySubscription(s, subData) {
        s.company.subscription = subData;
    },
    setCompanySettings(s, settings) {
        s.settings = settings;
    },
};

const actions = {
    init({ commit }) {
        commit('setService', new CompanyService());
    },
    setCompanyData({ commit }, company) {
        commit('setCompanyData', company);
    },
    setCompanySubscription({ commit }, subscription) {
        commit('setCompanySubscription', subscription);
    },
    setCompanySettings({ commit }, settings) {
        commit('setCompanySettings', settings);
    },
    async isSubscription({ dispatch, getters }, subName) {
        let company = getters.company;
        if (!company) {
            await dispatch('fetchCompanyData');
            company = getters.company;
        }

        if (company && company.subscription) {
            const subscriptionName = subName.charAt(0).toUpperCase() + subName.slice(1);
            return company.subscription.attributes.subscription.name === subscriptionName;
        }
        return false;
    },
    async fetchCompanyData({ commit, getters }) {
        const user = this.getters['user/user'];
        const { service } = getters;
        if (service && user.current_company_id) {
            const company = await service.getById(user.current_company_id);
            commit('setCompanyData', company);
            return company;
        }
        return null;
    },

    async fetchCompanySettings({ commit, getters }) {
        const { service } = getters;
        if (service) {
            const settings = await service.getSettings();
            commit('setCompanySettings', settings);
            return settings;
        }
        return null;
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
