import Vue from 'vue';
import ApiService from '@/services/api';
import UserService from '@/services/resource/user.service';

const state = {
    api: null,
    lastLogoutReason: null,
    user: {
        token: null,
        data: {},
        loggedIn: false,
        companyData: {},
    },
    service: null,
};

const getters = {
    user: s => s.user.data,
    token: s => s.user.token,
    loggedIn: s => s.user.loggedIn,
    companyData: s => s.user.companyData,
    apiService: s => s.api,
    lastLogoutReason: s => s.lastLogoutReason,
    service: s => s.service,
};

const mutations = {
    setService(s, serviceObject) {
        s.api = serviceObject;
    },

    setUserData(s, userData) {
        s.user.data = userData;
    },

    setUserToken(s, token) {
        s.user.token = token;
    },

    setLoggedInStatus(s, status) {
        s.user.loggedIn = status;
    },

    setCompanySettings(s, companyData) {
        s.user.companyData = companyData;
    },

    lastLogoutReason(s, reason) {
        s.lastLogoutReason = reason;
    },
    setUserService(s, service) {
        s.service = service;
    },
};

const actions = {
    init(ctx) {
        if (localStorage.getItem('access_token')) {
            ctx.commit('setUserToken', localStorage.getItem('access_token'));
            ctx.commit('setLoggedInStatus', true);
        }

        if (localStorage.getItem('lastLogoutReason')) {
            ctx.commit('lastLogoutReason', localStorage.getItem('lastLogoutReason'));
            localStorage.removeItem('lastLogoutReason');
        }

        ctx.commit('setService', new ApiService(ctx));
        ctx.commit('setUserService', new UserService());
    },

    setToken({ commit }, token) {
        commit('setUserToken', token);
    },

    setUser({ commit }, user) {
        commit('setUserData', user);
    },

    setLoggedInStatus({ commit }, status) {
        commit('setLoggedInStatus', status);
    },

    setCompanySettings: ({ commit }, data) => {
        commit('setCompanySettings', data);
    },

    forceUserExit({ commit }, { router, reason = null }) {
        const unsyncedIntervals = localStorage.getItem('unsyncedIntervals');

        localStorage.clear();
        sessionStorage.clear();

        if (unsyncedIntervals) {
            localStorage.setItem('unsyncedIntervals', unsyncedIntervals);
        }

        commit('setLoggedInStatus', false);
        commit('setUserToken', null);
        commit('setUserData', {});

        if (reason) {
            sessionStorage.setItem('lastLogoutReason', reason);
        }

        sessionStorage.setItem('logout', 'true');

        // Navigate to login page
        if (router) {
            router.push({ name: 'auth.login' }).catch(error => {
                if (error.name !== 'NavigationDuplicated') {
                    console.error('Error during route navigation:', error);
                }
            });
        }

        // Notify user about logout
        Vue.prototype.$Notify({
            group: 'auth',
            title: 'Warning',
            message: reason ? `You have been logged out. Reason: ${reason}` : 'You have been logged out',
            type: 'warning',
        });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
