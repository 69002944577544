<template>
    <div v-if="tasks" class="project-container">
        <div v-if="selectedTask" class="selected-project-container">
            <span
                v-show="selectedTask.id !== staticProject.id"
                class="color-indicator"
                :style="{ backgroundColor: selectedTask.attributes.color }"
            ></span>
        </div>
        <at-tooltip :content="selectedTaskNames">
            <template v-if="isMobile">
                <portal to="modals">
                    <task-modal
                        v-if="showModal"
                        v-model="showModal"
                        :projects="filteredTasks"
                        @select-task="handleTaskSelect"
                    ></task-modal>
                </portal>
                <div class="container-project" @click="showModal = true">
                    <span :style="containerProjectStyle"><img src="@/icon/tasks-solid.svg" alt="tasks" /></span>
                </div>
            </template>
            <template v-else>
                <at-dropdown :placement="position" :trigger="trigger" @on-dropdown-command="onDropdownCommand">
                    <div class="container-project">
                        <span><img src="@/icon/tasks-solid.svg" alt="tasks" /></span>
                    </div>
                    <at-dropdown-menu slot="menu" @click.native.stop>
                        <div class="dropdown-search">
                            <at-input v-model="search" :placeholder="$t('dashboard.taskSearch')"></at-input>
                        </div>
                        <div v-if="filteredTasks.length === 0" class="not-found-message">
                            {{ $t('dashboard.noTasks') }}
                        </div>
                        <div v-else class="dropdown-separator">
                            <at-dropdown-item
                                v-for="(task, index) in filteredTasks"
                                :key="index"
                                :name="task.attributes.task_name"
                                class="dropdown-item-container"
                                @click.native="selectTask(task)"
                            >
                                <div class="task-container">
                                    <i
                                        class="color-indicator"
                                        :style="{ backgroundColor: task.attributes.project.color }"
                                    ></i>
                                    <div class="project-name" :style="{ color: task.attributes.project.color }">
                                        {{ task.attributes.project.name }}
                                    </div>
                                    <i class="color-indicator" :style="{ backgroundColor: task.attributes.color }"></i>
                                    <div class="project-name">{{ task.attributes.task_name }}</div>
                                </div>
                                <at-tooltip :content="truncate(task.attributes.description)" placement="bottom-left">
                                    <div class="project-desc">{{ truncate(task.attributes.description) }}</div>
                                </at-tooltip>
                            </at-dropdown-item>
                        </div>
                        <at-button class="create-project-button" @click="createTask">
                            <i class="icon icon-plus"></i>
                            {{ $t('dashboard.buttons.createTask') }}
                        </at-button>
                    </at-dropdown-menu>
                </at-dropdown>
            </template>
        </at-tooltip>
    </div>
</template>
<script>
    import TaskModal from './TaskModal.vue';

    export default {
        name: 'TaskContainer',
        props: {
            selectedTaskID: {
                type: String,
                default: null,
            },
            tasks: {
                type: Array,
                required: true,
            },
        },
        components: {
            TaskModal,
        },
        data() {
            return {
                position: 'bottom',
                trigger: 'click',
                staticProject: {
                    id: 'static_project_id',
                    attributes: {
                        name: 'No Task',
                    },
                },
                selectedTask: null,
                search: '',
                showModal: false,
            };
        },
        computed: {
            isMobile() {
                return window.innerWidth <= 992;
            },
            selectedTaskNames() {
                if (this.selectedTask) {
                    return this.selectedTask.attributes.task_name;
                }
                return 'Choose Task';
            },
            shouldHideTooltip() {
                return this.selectedTask && this.isMobile && this.selectedTask.id !== this.staticProject.id;
            },
            containerProjectStyle() {
                return {
                    visibility: this.shouldHideTooltip ? 'hidden' : 'visible',
                    width: this.shouldHideTooltip ? 'auto' : '20px',
                };
            },
            filteredTasks() {
                return this.tasks.filter(task => {
                    const taskName = task.attributes && task.attributes.task_name;
                    return taskName && taskName.toLowerCase().includes(this.search.toLowerCase());
                });
            },
        },
        created() {
            if (this.selectedTaskID) this.findTaskByID(this.selectedTaskID);
        },
        methods: {
            stripHTML(html) {
                var tempDiv = document.createElement('div');
                tempDiv.innerHTML = html;
                return tempDiv.textContent || tempDiv.innerText || '';
            },
            truncate(value) {
                const text = this.stripHTML(value);
                const companyLength = 70;
                return text.length >= companyLength ? text.substring(0, companyLength) + '...' : text;
            },
            handleTaskSelect(task) {
                this.selectTask(task);
            },
            onDropdownCommand(command) {
                // Existing method to handle other commands
            },
            selectTask(task) {
                this.selectedTask = task.type === 'project' && task.id === 'static_project_id' ? null : task;
                this.$emit('task-selected', this.selectedTask);
            },
            createTask() {
                this.$router.push('/tasks/new');
            },
            findTaskByID(id) {
                this.selectedTask = this.tasks.find(task => {
                    return task.id == id;
                });
            },
        },
        watch: {
            selectedTaskID(id) {
                this.findTaskByID(id);
            },
        },
    };
</script>
<style lang="scss" scoped>
    .dropdown-item-container {
        .at-tooltip {
            overflow: visible;
            white-space: nowrap;
            width: 100%;
            .at-tooltip__content {
                white-space: wrap;
                max-width: none;
            }
        }
        .project-desc {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }
    .not-found-message {
        text-align: center;
        padding: 20px;
        width: 100%;
        font-size: 25px;
    }

    .selected-project-container {
        display: flex;
        justify-content: center;
    }

    .project-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
