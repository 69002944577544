<template>
    <div v-if="isOpen" class="modal-overlay">
        <div class="modal-content">
            <div class="modal-header">
                <h4>{{ $t('components.kanbanColumnSettings.columnTitle') }} {{ columnName }}</h4>
                <button class="close-button" @click="closeModal">&#x2715;</button>
            </div>

            <div class="modal-body">
                <div class="form-group" :class="{ 'has-error': errors.columnName }">
                    <label for="columnName">{{ $t('components.kanbanColumnSettings.columnName') }}</label>
                    <at-input
                        id="columnName"
                        v-model="columnName"
                        type="text"
                        :placeholder="$t('components.kanbanColumnSettings.namePlaceholder')"
                        @input="clearError('columnName')"
                        @change="validateForm"
                    />
                    <span v-if="errors.columnName" class="error-message">{{ errors.columnName }}</span>
                </div>

                <div class="form-group" :class="{ 'has-error': errors.columnPosition }">
                    <label for="columnPosition">{{ $t('components.kanbanColumnSettings.columnPosition') }}</label>
                    <at-input
                        id="columnPosition"
                        v-model="columnPosition"
                        type="number"
                        :placeholder="$t('components.kanbanColumnSettings.positionPlaceholder')"
                        @input="clearError('columnPosition')"
                        @change="validateForm"
                    />
                    <span v-if="errors.columnPosition" class="error-message">{{ errors.columnPosition }}</span>
                </div>

                <div class="form-actions">
                    <button class="at-btn at-btn--error at-btn--large action-button" @click="handleDeleteColumn">
                        <i class="icon icon-trash"></i> {{ $t('components.kanbanColumnSettings.buttons.delete') }}
                    </button>
                </div>

                <div class="buttom-footer">
                    <h4>{{ $t('components.kanbanColumnSettings.statusTitle') }} {{ columnName }}</h4>
                    <div class="circle-button" @click="openCreateStatusModal">
                        <span class="icon icon-plus-circle"></span>
                    </div>
                </div>
                <div v-if="columnStatuses.length != 0">
                    <h5>Used Statuses</h5>
                    <ul class="status-list">
                        <div class="status-items">
                            <li v-for="status in columnStatuses" :key="status.id" class="item">
                                <label>
                                    <input
                                        type="checkbox"
                                        class="custom-checkbox"
                                        :checked="isActiveStatus(status)"
                                        @change="toggleStatus(status)"
                                    />
                                    {{ status.name }}
                                </label>
                                <div class="visible">
                                    <button
                                        v-if="status.visible"
                                        @click="handleUpdateVisibleStatus(status.id, status.visible)"
                                    >
                                        <font-awesome-icon :icon="['fas', 'eye-slash']" />
                                    </button>
                                    <button v-else>
                                        <font-awesome-icon :icon="['fas', 'eye']" />
                                    </button>
                                    <button
                                        :disabled="status.system"
                                        class="trash-button"
                                        @click="handleRemoveStatus(status.id)"
                                    >
                                        <i class="icon icon-trash"></i>
                                    </button>
                                </div>
                            </li>
                        </div>
                    </ul>
                </div>
                <div v-if="unusedStatuses.length != 0" class="unused-statuses">
                    <h5>Unused Statuses</h5>
                    <ul class="status-list">
                        <div class="status-items">
                            <li v-for="status in unusedStatuses" :key="status.attributes.id" class="item">
                                <label>
                                    <input
                                        type="checkbox"
                                        class="custom-checkbox"
                                        :checked="isActiveStatus(status.attributes)"
                                        @change="toggleStatus(status.attributes)"
                                    />
                                    {{ status.attributes.name }}
                                </label>
                                <div class="visible">
                                    <button
                                        v-if="status.attributes.visible"
                                        @click="
                                            handleUpdateVisibleStatus(status.attributes.id, status.attributes.visible)
                                        "
                                    >
                                        <font-awesome-icon :icon="['fas', 'eye-slash']" />
                                    </button>
                                    <button v-else>
                                        <font-awesome-icon :icon="['fas', 'eye']" />
                                    </button>
                                    <button
                                        v-if="!status.attributes.system"
                                        @click="handleRemoveStatus(status.attributes.id)"
                                    >
                                        <i class="icon icon-trash"></i>
                                    </button>
                                </div>
                            </li>
                        </div>
                    </ul>
                </div>
                <div v-else class="no-available-statuses">
                    <h5>No available statuses</h5>
                </div>
            </div>
        </div>

        <confirmation-modal
            v-if="showConfirmModal"
            :visible="showConfirmModal"
            :message="$t('components.kanbanColumnSettings.confirmDelete')"
            @confirm="deleteColumn"
            @close="closeConfirmModal"
        />

        <confirmation-modal
            v-if="showDestroyStatusModal"
            :visible="showDestroyStatusModal"
            :message="$t('components.kanbanColumnSettings.confirmDelete')"
            @confirm="deleteStatus"
            @close="closeDestroyStatusModal"
        />

        <create-status-modal
            v-if="showCreateStatusModal"
            :visible="showCreateStatusModal"
            @close="closeCreateStatusModal"
            @status-created="fetchStatuses"
        />
    </div>
</template>

<script>
    import StatusService from '../../Statuses/services/statuse.service';
    import KanbanColumnService from '../services/kanban-column.service';
    import ConfirmationModal from '../../../components/DeleteModal.vue';
    import CreateStatusModal from '../components/CreateStatusModel.vue';
    import debounce from 'lodash/debounce';
    import { mapGetters } from 'vuex';

    let reconnectInterval = 5000;
    let maxRetries = 10;
    let retries = 0;

    export default {
        components: {
            ConfirmationModal,
            CreateStatusModal,
        },
        props: {
            isOpen: Boolean,
            currentColumn: Object,
            columnStatuses: Array,
            usedStatusesID: Array,
        },
        data() {
            return {
                columnID: this.currentColumn.attributes.id,
                columnName: this.currentColumn.attributes.name,
                columnPosition: this.currentColumn.attributes.position,
                statuses: [],
                errors: {
                    columnName: null,
                    columnPosition: null,
                },
                removedStatusID: null,
                showConfirmModal: false,
                showDestroyStatusModal: false,
                showCreateStatusModal: false,
                kanbanColumnService: new KanbanColumnService(),
                statusService: new StatusService(),
                wsUrl: process.env.VUE_APP_WS_URL || '',
                ws: null,
            };
        },
        computed: {
            ...mapGetters('company', ['company']),
            unusedStatuses() {
                return this.statuses.filter(status => !this.usedStatusesID.includes(status.attributes.id));
            },
        },
        async created() {
            await this.fetchStatuses();
            this.connectWebSocket();
        },
        methods: {
            async fetchStatuses() {
                try {
                    const { data } = await this.statusService.getAll({ disable_pagy: true });
                    this.statuses = data.data.filter(status => status.attributes.visible);
                } catch (error) {
                    console.log('error request index status', error);
                }
            },

            isActiveStatus(status) {
                return this.columnStatuses.some(columnStatus => columnStatus.id === status.id);
            },

            toggleStatus(status) {
                if (this.isActiveStatus(status)) {
                    this.removeStatusFromColumn({
                        kanban_column_status: {
                            kanban_column_id: this.currentColumn.attributes.id,
                            status_id: status.id,
                        },
                    });
                } else {
                    this.addStatusToColumn({
                        kanban_column_status: {
                            kanban_column_id: this.currentColumn.attributes.id,
                            status_id: status.id,
                        },
                    });
                }
            },
            async addStatusToColumn(params) {
                try {
                    const res = await this.kanbanColumnService.addStatusToColumn(params);
                    if (res.status == 201) {
                        this.successMessage();
                    }
                } catch (error) {
                    console.log('error request add status to Column', error);
                }
            },

            async removeStatusFromColumn(params) {
                try {
                    const res = await this.kanbanColumnService.removeStatusFromColumn(params);
                    if (res.status == 204) {
                        this.successMessage();
                    }
                } catch (error) {
                    console.log('error request remove status from Column', error);
                }
            },

            validateForm: debounce(async function () {
                this.errors.columnName = !this.columnName
                    ? this.$t('components.kanbanColumnSettings.errors.name')
                    : null;
                this.errors.columnPosition = !this.columnPosition
                    ? this.$t('components.kanbanColumnSettings.errors.position')
                    : null;

                if (!this.errors.columnName && !this.errors.columnPosition) {
                    this.submitForm();
                }
            }, 1000),
            async submitForm() {
                try {
                    const formData = new FormData();
                    formData.append('kanban_column[name]', this.columnName);
                    formData.append('kanban_column[position]', this.columnPosition);
                    const res = await this.kanbanColumnService.update(formData, this.columnID);

                    if (res.status == 200) {
                        this.successMessage();
                    }
                } catch (error) {
                    console.log('Error updating Kanban Column', error);
                }
            },

            handleDeleteColumn() {
                this.showConfirmModal = true;
            },

            handleRemoveStatus(statusID) {
                this.removedStatusID = statusID;
                this.showDestroyStatusModal = true;
            },
            async handleUpdateVisibleStatus(statusID, visible) {
                try {
                    const res = await this.statusService.update({ status: { visible: !visible } }, statusID);
                    if (res.status == 200) {
                        this.successMessage();
                    }
                } catch (error) {
                    console.log('error request index status', error);
                }
            },
            async deleteStatus() {
                try {
                    await this.statusService.deleteItem(this.removedStatusID);
                    this.closeDestroyStatusModal();
                } catch (error) {
                    console.log('error request destroy status', error);
                }
            },
            async deleteColumn() {
                this.showConfirmModal = false;
                try {
                    const res = await this.kanbanColumnService.delete(this.columnID);

                    if (res.status == 204) {
                        this.successMessage();
                        this.$emit('close');
                    }
                } catch (error) {
                    console.log('Error destroying column', error);
                }
            },

            closeModal() {
                this.$emit('close');
            },

            closeCreateStatusModal() {
                this.showCreateStatusModal = false;
            },

            openCreateStatusModal() {
                this.showCreateStatusModal = true;
            },

            clearError(field) {
                this.errors[field] = null;
            },

            closeConfirmModal() {
                this.showConfirmModal = false;
            },

            closeDestroyStatusModal() {
                this.removedStatusID = null;
                this.showDestroyStatusModal = false;
            },

            successMessage() {
                this.$Notify({
                    type: 'success',
                    title: this.$t('notification.save.success.title'),
                    message: this.$t('notification.save.success.message'),
                });
            },

            connectWebSocket() {
                if (!this.wsUrl) return;
                if (!this.company) return;

                this.ws = new WebSocket(this.wsUrl);

                this.ws.onopen = () => {
                    retries = 0;

                    const subTimer = {
                        command: 'subscribe',
                        identifier: JSON.stringify({
                            channel: 'StatusChannel',
                            company_id: this.company.id,
                        }),
                    };

                    this.ws.send(JSON.stringify(subTimer));
                };

                this.ws.onmessage = event => {
                    const data = JSON.parse(event.data);
                    if (data.type !== 'ping' && data.message) {
                        const { action, status } = data.message;

                        const actions = {
                            new_status: () => this.wsAddNewStatus(status),
                            remove_status: () => this.wsRemoveStatus(status),
                            update_status: () => this.wsUpdateStatus(status),
                        };

                        if (actions[action]) {
                            actions[action]();
                        } else {
                            console.warn(`Unhandled action: ${action}`);
                        }
                    }
                };

                this.ws.onclose = () => {
                    console.log('WebSocket connection closed');
                    this.retryWebSocketConnection();
                };

                this.ws.onerror = error => {
                    console.error('WebSocket error:', error);
                    this.retryWebSocketConnection();
                };
            },
            retryWebSocketConnection() {
                if (retries < maxRetries) {
                    retries += 1;
                    console.log(`Try connecting (${retries}/${maxRetries})...`);
                    setTimeout(() => {
                        this.connectWebSocket();
                    }, reconnectInterval);
                } else {
                    console.log('The maximum number of reconnections has been reached.');
                }
            },

            wsAddNewStatus(status) {
                this.statuses.push(status.data);
            },

            wsRemoveStatus(statusObj) {
                this.statuses = this.statuses.filter(status => status.attributes.id != statusObj.id);
            },

            wsUpdateStatus(statusObj) {
                this.statuses = this.statuses.filter(status => status.attributes.id != statusObj.data.id);
                this.statuses.push(statusObj.data);
                this.statuses = this.statuses.filter(status => status.attributes.visible);
            },
        },
    };
</script>

<style scoped lang="scss">
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-content {
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        width: 400px;
        max-width: 90%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

        .theme-dark & {
            background: #222;
            color: #ffa500;
            box-shadow: 0 12px 24px rgba(0, 0, 0, 0.5);

            &::v-deep .at-input__original {
                background-color: #555;
            }
        }
    }

    .buttom-footer {
        display: flex;
        justify-content: space-between;
    }

    .circle-button {
        cursor: pointer;
    }

    .no-available-statuses {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .modal-body {
        padding: 10px 0;
    }

    .form-group {
        margin-bottom: 15px;
    }

    .form-group.has-error input {
        border-color: red;
    }

    .error-message {
        color: red;
        font-size: 12px;
    }

    .form-actions {
        display: flex;
        justify-content: right;
        margin-bottom: 15px;
    }

    .status-list {
        list-style-type: none;
        padding: 0;
        margin-bottom: 10px;
    }

    .status-list li {
        padding: 5px 0;
        display: flex;
        align-items: center;
    }

    .status-list label {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .status-list input[type='checkbox'] {
        margin-right: 10px;
    }

    .custom-checkbox {
        width: 20px;
        height: 20px;
        appearance: none;
        border: 2px solid #ccc;
        border-radius: 4px;
        display: inline-block;
        position: relative;
        cursor: pointer;
        outline: none;
    }

    .custom-checkbox:checked {
        background-color: #4caf50;
        border-color: #4caf50;
    }

    .custom-checkbox:checked::after {
        content: '\2714';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
        color: white;
    }

    .status-items {
        display: flex;
        flex-direction: column;
    }

    .item {
        display: flex;
        justify-content: space-between;
    }

    .icon-plus-circle,
    .close-button,
    .icon-trash {
        &:hover {
            color: #ffa500;
        }
        .theme-dark & {
            &:hover {
                color: #c4c4cf;
            }
        }
    }

    h5 {
        border-bottom: 1px solid #3f536e;

        .theme-dark & {
            border-color: #555;
        }
    }

    .visible {
        display: flex;
        gap: 10px;
    }

    .trash-button:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
</style>
