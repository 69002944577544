import { v4 as uuidv4 } from 'uuid';

const state = {
    unsyncedIntervals: JSON.parse(localStorage.getItem('unsyncedIntervals')) || {},
};

const getters = {
    getIntervalsByCompany: state => companyId => {
        return state.unsyncedIntervals[companyId] || [];
    },
};

const mutations = {
    SET_INTERVALS(state, { companyId, intervals }) {
        state.unsyncedIntervals = {
            ...state.unsyncedIntervals,
            [companyId]: intervals,
        };
        localStorage.setItem('unsyncedIntervals', JSON.stringify(state.unsyncedIntervals));
    },

    UPDATE_INTERVAL(state, { companyId, uuid, updatedInterval }) {
        if (state.unsyncedIntervals[companyId]) {
            const intervals = state.unsyncedIntervals[companyId].map(interval =>
                interval.uuid === uuid ? { ...interval, ...updatedInterval } : interval,
            );
            state.unsyncedIntervals[companyId] = intervals;
            localStorage.setItem('unsyncedIntervals', JSON.stringify(state.unsyncedIntervals));
        }
    },

    REMOVE_INTERVAL(state, { companyId, uuid }) {
        if (state.unsyncedIntervals[companyId]) {
            const intervals = state.unsyncedIntervals[companyId].filter(interval => interval.uuid !== uuid);
            state.unsyncedIntervals[companyId] = intervals;
            localStorage.setItem('unsyncedIntervals', JSON.stringify(state.unsyncedIntervals));
        }
    },

    CLEAR_INTERVALS(state, companyId) {
        if (state.unsyncedIntervals[companyId]) {
            const { [companyId]: _, ...rest } = state.unsyncedIntervals;
            state.unsyncedIntervals = rest;
            localStorage.setItem('unsyncedIntervals', JSON.stringify(state.unsyncedIntervals));
        }
    },
};

const actions = {
    saveInterval({ commit, state }, { companyId, interval }) {
        const intervalWithUuid = { ...interval, uuid: uuidv4() };
        const intervals = [...(state.unsyncedIntervals[companyId] || []), intervalWithUuid];
        commit('SET_INTERVALS', { companyId, intervals });
    },

    updateLocalInterval({ commit }, { companyId, uuid, updatedInterval }) {
        commit('UPDATE_INTERVAL', { companyId, uuid, updatedInterval });
    },

    removeLocalInterval({ commit }, { companyId, uuid }) {
        commit('REMOVE_INTERVAL', { companyId, uuid });
    },

    clearIntervals({ commit }, companyId) {
        commit('CLEAR_INTERVALS', companyId);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
