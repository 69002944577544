import axios from '@/config/app';
import SettingsService from '@/services/settings.service';
import Store from '@/store';
import i18n from '@/i18n';
import moment from 'moment';

/**
 * Section service class.
 * Used to fetch data from api for inside DynamicSettings.vue
 * Data is stored inside store -> settings -> sections -> data
 */
export default class AccountService extends SettingsService {
    getItemRequestUri() {
        return `/api/v1/auth/me`;
    }

    getAll() {
        let user = Store.getters['user/user'];
        if (Object.keys(user).length) {
            Promise.resolve().then(() => {
                return {
                    data: {
                        id: user.id,
                        password: user.password,
                        email: user.email,
                        full_name: user.full_name,
                        user_language: user.user_language,
                    },
                };
            });
        }

        return axios.get(`api/v1/users/${user.id}`, { ignoreCancel: true }).then(({ data }) => {
            const values = data.data.attributes;
            return {
                id: values.id,
                password: values.password,
                email: values.email,
                full_name: values.full_name,
                user_language: values.user_language,
            };
        });
    }

    save(data) {
        i18n.locale = data.user_language;
        moment.locale(data.user_language);
        return axios.put(`api/v1/users/${data.id}`, data).then(({ data }) => {
            const res = data.data.attributes;
            return {
                data: {
                    attributes: {
                        id: res.id,
                        password: res.password,
                        email: res.email,
                        full_name: res.full_name,
                        user_language: res.user_language,
                    },
                },
            };
        });
    }
}
